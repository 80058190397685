body, html, #root, main {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	background-color: #fafafa
}
body {
	font-family: 'Domine', serif;
	font-size: 15px;
	letter-spacing: 0.5px;
	line-height: 1.5;
	color: #1c1b1b;
	overflow-x: hidden,
}
ul {
	padding: 0;
	margin: 0;
}

ul li {
	list-style: disc;
	font-size: 17px;
	line-height: 1.59;
}

a {
	text-decoration: none;
	color: #2f2f2f;
}

a:hover,
a:focus {
	outline: none;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Domine', serif;
	font-weight: 700;
	margin-top: 0;
}


h1 {
	font-size: 38px;
}

h2 {
	font-size: 32px;
	line-height: 1.13;
}

h3 {
	font-size: 26px;
}

h4 {
	font-size: 22px;
}

h5 {
	font-size: 20px;
}

h6 {
	font-size: 18px;
}

input::-webkit-clear-button {
	-webkit-appearance: none;
	margin: 0;
}
.app-logo {
    font-size: 35px;
    font-family: 'Domine', sans-serif;
    display: inline-block;
    font-weight: 600;

    padding: 5px 20px;
    margin-bottom: 30px;
}
.myappbar {
	background-color: #222!important;
}

figure {
	-webkit-margin-start: 0px;
	-webkit-margin-end: 0px
}
.mu-social-media {
    display: inline;
    float: left;
    padding: 10px 0;
    width: 100%;
}
.mu-social-media a {
    border: 1px solid #19191a;
    border-radius: 50px;
    color: #19191a;
    display: inline-block;
    font-size: 12px;
    margin-right: 10px;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out 0s;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
}
.mu-social-media a:hover {
border: 1px solid #f50057;
    color: #f50057;

}
.react_Video div iframe {
	position: absolute;
top: 0;
left: 0;
}

